import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: "app-arbt",
  templateUrl: "./arbt.component.html",
  styleUrls: ["./arbt.component.scss"]
})
export class ArbtComponent implements OnInit, OnDestroy {

  constructor() { }

  ngOnInit() {
    const body = document.getElementsByTagName("body")[0];
    body.classList.add("arbt-page");
  }
  ngOnDestroy() {
    const body = document.getElementsByTagName("body")[0];
    body.classList.remove("arbt-page");
  }

}
