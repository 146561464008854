import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule, Routes } from "@angular/router";
import { LandingpageComponent } from "./pages/examples/landingpage/landingpage.component";
import { ArbtComponent } from "./pages/arbt/arbt.component";

const routes: Routes = [
    { path: "", redirectTo: "landing", pathMatch: "full" },
    // { path: "home", component: IndexComponent },
    // { path: "profile", component: ProfilepageComponent },
    // { path: "register", component: RegisterpageComponent },
    { path: "landing", component: LandingpageComponent },
    { path: "arbt", component: ArbtComponent }
];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes, {
            useHash: true
        })
    ],
    exports: []
})
export class AppRoutingModule {
}
